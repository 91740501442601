:root[data-seed] {
  --seed-text-button-focus-border: 2px solid var(--seed-scale-color-blue-600);
  --seed-text-button-primary-color: var(--seed-semantic-color-primary);
  --seed-text-button-primary-hovered-color: var(--seed-semantic-color-primary-hover);
  --seed-text-button-primary-pressed-color: var(--seed-semantic-color-primary-pressed);
  --seed-text-button-primary-disabled-color: var(--seed-scale-color-gray-400);
  --seed-text-button-secondary-color: var(--seed-semantic-color-secondary);
  --seed-text-button-secondary-hovered-color: var(--seed-scale-color-gray-700);
  --seed-text-button-secondary-pressed-color: var(--seed-scale-color-gray-700);
  --seed-text-button-secondary-disabled-color: var(--seed-scale-color-gray-400);
  --seed-text-button-secondary-low-color: var(--seed-scale-color-gray-600);
  --seed-text-button-secondary-low-hovered-color: var(--seed-scale-color-gray-700);
  --seed-text-button-secondary-low-pressed-color: var(--seed-scale-color-gray-700);
  --seed-text-button-secondary-low-disabled-color: var(--seed-scale-color-gray-400);
  --seed-text-button-accent-color: var(--seed-semantic-color-accent);
  --seed-text-button-accent-hovered-color: var(--seed-scale-color-blue-400);
  --seed-text-button-accent-pressed-color: var(--seed-scale-color-blue-400);
  --seed-text-button-accent-disabled-color: var(--seed-scale-color-gray-400);
  --seed-text-button-danger-color: var(--seed-semantic-color-danger);
  --seed-text-button-danger-hovered-color: var(--seed-scale-color-red-400);
  --seed-text-button-danger-pressed-color: var(--seed-scale-color-red-400);
  --seed-text-button-danger-disabled-color: var(--seed-scale-color-gray-400);
  --seed-text-button-large-padding-x: 20px;
  --seed-text-button-large-padding-y: 14px;
  --seed-text-button-large-border-radius: 6px;
  --seed-text-button-medium-padding-x: 16px;
  --seed-text-button-medium-padding-y: 10px;
  --seed-text-button-medium-border-radius: 6px;
  --seed-text-button-small-padding-x: 14px;
  --seed-text-button-small-padding-y: 8px;
  --seed-text-button-small-border-radius: 4px;
  --seed-text-button-regular-large-font-size: var(--seed-semantic-typography-label1-regular-font-size);
  --seed-text-button-regular-large-font-weight: var(--seed-semantic-typography-label1-regular-font-weight);
  --seed-text-button-regular-large-line-height: var(--seed-semantic-typography-label1-regular-line-height);
  --seed-text-button-regular-large-letter-spacing: var(--seed-semantic-typography-label1-regular-letter-spacing);
  --seed-text-button-regular-medium-font-size: var(--seed-semantic-typography-label2-regular-font-size);
  --seed-text-button-regular-medium-font-weight: var(--seed-semantic-typography-label2-regular-font-weight);
  --seed-text-button-regular-medium-line-height: var(--seed-semantic-typography-label2-regular-line-height);
  --seed-text-button-regular-medium-letter-spacing: var(--seed-semantic-typography-label2-regular-letter-spacing);
  --seed-text-button-regular-small-font-size: var(--seed-semantic-typography-label3-regular-font-size);
  --seed-text-button-regular-small-font-weight: var(--seed-semantic-typography-label3-regular-font-weight);
  --seed-text-button-regular-small-line-height: var(--seed-semantic-typography-label3-regular-line-height);
  --seed-text-button-regular-small-letter-spacing: var(--seed-semantic-typography-label3-regular-letter-spacing);
  --seed-text-button-bold-large-font-size: var(--seed-semantic-typography-label1-bold-font-size);
  --seed-text-button-bold-large-font-weight: var(--seed-semantic-typography-label1-bold-font-weight);
  --seed-text-button-bold-large-line-height: var(--seed-semantic-typography-label1-bold-line-height);
  --seed-text-button-bold-large-letter-spacing: var(--seed-semantic-typography-label1-bold-letter-spacing);
  --seed-text-button-bold-medium-font-size: var(--seed-semantic-typography-label2-bold-font-size);
  --seed-text-button-bold-medium-font-weight: var(--seed-semantic-typography-label2-bold-font-weight);
  --seed-text-button-bold-medium-line-height: var(--seed-semantic-typography-label2-bold-line-height);
  --seed-text-button-bold-medium-letter-spacing: var(--seed-semantic-typography-label2-bold-letter-spacing);
  --seed-text-button-bold-small-font-size: var(--seed-semantic-typography-label3-bold-font-size);
  --seed-text-button-bold-small-font-weight: var(--seed-semantic-typography-label3-bold-font-weight);
  --seed-text-button-bold-small-line-height: var(--seed-semantic-typography-label3-bold-line-height);
  --seed-text-button-bold-small-letter-spacing: var(--seed-semantic-typography-label3-bold-letter-spacing);
}
