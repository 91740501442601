:root[data-seed] {
  --seed-box-button-focus-border: 2px solid var(--seed-scale-color-blue-600);
  --seed-box-button-primary-enabled-background: var(--seed-semantic-color-primary);
  --seed-box-button-primary-enabled-color: var(--seed-semantic-color-on-primary);
  --seed-box-button-primary-low-enabled-background: var(--seed-semantic-color-primary-low);
  --seed-box-button-primary-low-enabled-color: var(--seed-semantic-color-primary);
  --seed-box-button-secondary-enabled-background: var(--seed-scale-color-gray-100);
  --seed-box-button-secondary-enabled-color: var(--seed-scale-color-gray-900);
  --seed-box-button-danger-enabled-background: var(--seed-semantic-color-danger);
  --seed-box-button-danger-enabled-color: var(--seed-static-color-static-white);
  --seed-box-button-primary-hovered-background: var(--seed-semantic-color-primary-hover);
  --seed-box-button-primary-hovered-color: var(--seed-semantic-color-on-primary);
  --seed-box-button-primary-low-hovered-background: var(--seed-semantic-color-primary-low-hover);
  --seed-box-button-primary-low-hovered-color: var(--seed-semantic-color-primary);
  --seed-box-button-secondary-hovered-background: var(--seed-scale-color-gray-300);
  --seed-box-button-secondary-hovered-color: var(--seed-scale-color-gray-900);
  --seed-box-button-danger-hovered-background: var(--seed-scale-color-red-400);
  --seed-box-button-danger-hovered-color: var(--seed-static-color-static-white);
  --seed-box-button-primary-pressed-background: var(--seed-semantic-color-primary-pressed);
  --seed-box-button-primary-pressed-color: var(--seed-semantic-color-on-primary);
  --seed-box-button-primary-low-pressed-background: var(--seed-semantic-color-primary-low-pressed);
  --seed-box-button-primary-low-pressed-color: var(--seed-semantic-color-primary);
  --seed-box-button-secondary-pressed-background: var(--seed-scale-color-gray-300);
  --seed-box-button-secondary-pressed-color: var(--seed-scale-color-gray-900);
  --seed-box-button-danger-pressed-background: var(--seed-scale-color-red-400);
  --seed-box-button-danger-pressed-color: var(--seed-static-color-static-white);
  --seed-box-button-primary-disabled-background: var(--seed-scale-color-gray-300);
  --seed-box-button-primary-disabled-color: var(--seed-scale-color-gray-500);
  --seed-box-button-primary-low-disabled-background: var(--seed-scale-color-gray-300);
  --seed-box-button-primary-low-disabled-color: var(--seed-scale-color-gray-500);
  --seed-box-button-secondary-disabled-background: var(--seed-scale-color-gray-300);
  --seed-box-button-secondary-disabled-color: var(--seed-scale-color-gray-500);
  --seed-box-button-danger-disabled-background: var(--seed-scale-color-gray-300);
  --seed-box-button-danger-disabled-color: var(--seed-scale-color-gray-500);
  --seed-box-button-xlarge-padding-x: 22px;
  --seed-box-button-xlarge-padding-y: 14px;
  --seed-box-button-xlarge-border-radius: 6px;
  --seed-box-button-large-padding-x: 20px;
  --seed-box-button-large-padding-y: 14px;
  --seed-box-button-large-border-radius: 6px;
  --seed-box-button-medium-padding-x: 16px;
  --seed-box-button-medium-padding-y: 10px;
  --seed-box-button-medium-border-radius: 6px;
  --seed-box-button-small-padding-x: 14px;
  --seed-box-button-small-padding-y: 8px;
  --seed-box-button-small-border-radius: 4px;
  --seed-box-button-xsmall-padding-x: 10px;
  --seed-box-button-xsmall-padding-y: 8px;
  --seed-box-button-xsmall-border-radius: 4px;
}
