:root[data-seed] {
  --seed-box-toggle-button-focus-border: 2px solid var(--seed-scale-color-blue-600);
  --seed-box-toggle-button-primary-enabled-selected-background: var(--seed-semantic-color-secondary-low);
  --seed-box-toggle-button-primary-enabled-selected-color: var(--seed-semantic-color-gray-900);
  --seed-box-toggle-button-primary-enabled-background: var(--seed-semantic-color-primary);
  --seed-box-toggle-button-primary-enabled-color: var(--seed-semantic-color-on-primary);
  --seed-box-toggle-button-primary-hovered-selected-background: var(--seed-scale-color-gray-200);
  --seed-box-toggle-button-primary-hovered-selected-color: var(--seed-scale-color-gray-900);
  --seed-box-toggle-button-primary-hovered-background: var(--seed-semantic-color-primary-hover);
  --seed-box-toggle-button-primary-hovered-color: var(--seed-semantic-color-on-primary);
  --seed-box-toggle-button-primary-pressed-selected-background: var(--seed-scale-color-gray-200);
  --seed-box-toggle-button-primary-pressed-selected-color: var(--seed-scale-color-gray-900);
  --seed-box-toggle-button-primary-pressed-background: var(--seed-semantic-color-primary-pressed);
  --seed-box-toggle-button-primary-pressed-color: var(--seed-semantic-color-on-primary);
  --seed-box-toggle-button-primary-disabled-selected-background: var(--seed-scale-color-gray-300);
  --seed-box-toggle-button-primary-disabled-selected-color: var(--seed-scale-color-gray-500);
  --seed-box-toggle-button-primary-low-enabled-selected-background: var(--seed-semantic-color-secondary-low);
  --seed-box-toggle-button-primary-low-enabled-selected-color: var(--seed-scale-color-gray-900);
  --seed-box-toggle-button-primary-low-enabled-background: var(--seed-semantic-color-primary-low);
  --seed-box-toggle-button-primary-low-enabled-color: var(--seed-semantic-color-primary);
  --seed-box-toggle-button-primary-low-hovered-selected-background: var(--seed-scale-color-gray-200);
  --seed-box-toggle-button-primary-low-hovered-selected-color: var(--seed-scale-color-gray-900);
  --seed-box-toggle-button-primary-low-hovered-background: var(--seed-semantic-color-primary-low-hover);
  --seed-box-toggle-button-primary-low-hovered-color: var(--seed-semantic-color-primary);
  --seed-box-toggle-button-primary-low-pressed-selected-background: var(--seed-scale-color-gray-200);
  --seed-box-toggle-button-primary-low-pressed-selected-color: var(--seed-scale-color-gray-900);
  --seed-box-toggle-button-primary-low-pressed-background: var(--seed-semantic-color-primary-low-pressed);
  --seed-box-toggle-button-primary-low-pressed-color: var(--seed-semantic-color-primary);
  --seed-box-toggle-button-primary-low-disabled-selected-background: var(--seed-scale-color-gray-300);
  --seed-box-toggle-button-primary-low-disabled-selected-color: var(--seed-scale-color-gray-500);
  --seed-box-toggle-button-primary-low-disabled-background: var(--seed-scale-color-gray-300);
  --seed-box-toggle-button-primary-low-disabled-color: var(--seed-scale-color-gray-500);
  --seed-box-toggle-button-secondary-enabled-selected-background: var(--seed-semantic-color-primary-low);
  --seed-box-toggle-button-secondary-enabled-selected-color: var(--seed-semantic-color-primary);
  --seed-box-toggle-button-secondary-enabled-background: var(--seed-semantic-color-secondary-low);
  --seed-box-toggle-button-secondary-enabled-color: var(--seed-scale-color-gray-900);
  --seed-box-toggle-button-secondary-hovered-selected-background: var(--seed-semantic-color-primary-low-hover);
  --seed-box-toggle-button-secondary-hovered-selected-color: var(--seed-semantic-color-primary);
  --seed-box-toggle-button-secondary-hovered-background: var(--seed-scale-color-gray-200);
  --seed-box-toggle-button-secondary-hovered-color: var(--seed-scale-color-gray-900);
  --seed-box-toggle-button-secondary-pressed-selected-background: var(--seed-semantic-color-primary-low-pressed);
  --seed-box-toggle-button-secondary-pressed-selected-color: var(--seed-semantic-color-primary);
  --seed-box-toggle-button-secondary-pressed-background: var(--seed-scale-color-gray-200);
  --seed-box-toggle-button-secondary-pressed-color: var(--seed-scale-color-gray-900);
  --seed-box-toggle-button-secondary-disabled-selected-background: var(--seed-scale-color-gray-300);
  --seed-box-toggle-button-secondary-disabled-selected-color: var(--seed-scale-color-gray-500);
  --seed-box-toggle-button-secondary-disabled-background: var(--seed-scale-color-gray-300);
  --seed-box-toggle-button-secondary-disabled-color: var(--seed-scale-color-gray-500);
  --seed-box-toggle-button-large-padding-x: 20px;
  --seed-box-toggle-button-large-padding-y: 14px;
  --seed-box-toggle-button-medium-padding-x: 16px;
  --seed-box-toggle-button-medium-padding-y: 10px;
  --seed-box-toggle-button-small-padding-x: 14px;
  --seed-box-toggle-button-small-padding-y: 8px;
  --seed-box-toggle-button-xsmall-padding-x: 10px;
  --seed-box-toggle-button-xsmall-padding-y: 8px;
  --seed-box-toggle-button-basic-xsmall-border-radius: 4px;
  --seed-box-toggle-button-basic-small-border-radius: 4px;
  --seed-box-toggle-button-basic-medium-border-radius: 6px;
  --seed-box-toggle-button-basic-large-border-radius: 6px;
}
