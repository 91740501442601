.seed-capsule-toggle-button_M1eM7q {
  box-sizing: border-box;
  cursor: pointer;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  border: none;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  display: inline-flex;
}

.seed-capsule-toggle-button_M1eM7q[data-stretch="true"] {
  flex: 1;
}

.seed-capsule-toggle-button_M1eM7q[data-disabled] {
  cursor: not-allowed;
}

.seed-capsule-toggle-button_M1eM7q[data-focus]:not([data-focus-visible]) {
  outline: none;
}

.seed-capsule-toggle-button_M1eM7q[data-focus][data-focus-visible] {
  outline: var(--seed-capsule-toggle-button-focus-border);
}

.seed-capsule-toggle-button_M1eM7q {
  border-radius: var(--seed-capsule-toggle-button-border-radius);
}

.seed-capsule-toggle-button_M1eM7q[data-size="small"] {
  padding: var(--seed-capsule-toggle-button-small-padding-y) var(--seed-capsule-toggle-button-small-padding-x);
}

.seed-capsule-toggle-button_M1eM7q[data-size="xsmall"] {
  padding: var(--seed-capsule-toggle-button-xsmall-padding-y) var(--seed-capsule-toggle-button-xsmall-padding-x);
}

.seed-capsule-toggle-button_M1eM7q {
  background-color: var(--seed-capsule-toggle-button-secondary-enabled-background);
  color: var(--seed-capsule-toggle-button-secondary-enabled-color);
}

.seed-capsule-toggle-button_M1eM7q[data-selected] {
  background-color: var(--seed-capsule-toggle-button-secondary-enabled-selected-background);
  color: var(--seed-capsule-toggle-button-secondary-enabled-selected-color);
}

.seed-capsule-toggle-button_M1eM7q[data-hover]:not([data-disabled]):not([data-pressed]) {
  background-color: var(--seed-capsule-toggle-button-secondary-hovered-background);
  color: var(--seed-capsule-toggle-button-secondary-hovered-color);
}

.seed-capsule-toggle-button_M1eM7q[data-hover]:not([data-disabled]):not([data-pressed])[data-selected] {
  background-color: var(--seed-capsule-toggle-button-secondary-hovered-selected-background);
  color: var(--seed-capsule-toggle-button-secondary-hovered-selected-color);
}

.seed-capsule-toggle-button_M1eM7q[data-pressed]:not([data-disabled]) {
  background-color: var(--seed-capsule-toggle-button-secondary-pressed-background);
  color: var(--seed-capsule-toggle-button-secondary-pressed-color);
}

.seed-capsule-toggle-button_M1eM7q[data-pressed]:not([data-disabled])[data-selected] {
  background-color: var(--seed-capsule-toggle-button-secondary-pressed-selected-background);
  color: var(--seed-capsule-toggle-button-secondary-pressed-selected-color);
}

.seed-capsule-toggle-button_M1eM7q[data-disabled] {
  background-color: var(--seed-capsule-toggle-button-secondary-disabled-background);
  color: var(--seed-capsule-toggle-button-secondary-disabled-color);
}

.seed-capsule-toggle-button_M1eM7q[data-selected][data-disabled] {
  background-color: var(--seed-capsule-toggle-button-secondary-disabled-selected-background);
  color: var(--seed-capsule-toggle-button-secondary-disabled-selected-color);
}
