:root[data-seed] {
  --seed-capsule-toggle-button-focus-border: 2px solid var(--seed-scale-color-blue-600);
  --seed-capsule-toggle-button-secondary-enabled-selected-background: var(--seed-semantic-color-primary-low);
  --seed-capsule-toggle-button-secondary-enabled-selected-color: var(--seed-semantic-color-primary);
  --seed-capsule-toggle-button-secondary-enabled-background: var(--seed-semantic-color-secondary-low);
  --seed-capsule-toggle-button-secondary-enabled-color: var(--seed-scale-color-gray-900);
  --seed-capsule-toggle-button-secondary-hovered-selected-background: var(--seed-semantic-color-primary-low-hover);
  --seed-capsule-toggle-button-secondary-hovered-selected-color: var(--seed-semantic-color-primary);
  --seed-capsule-toggle-button-secondary-hovered-background: var(--seed-scale-color-gray-200);
  --seed-capsule-toggle-button-secondary-hovered-color: var(--seed-scale-color-gray-900);
  --seed-capsule-toggle-button-secondary-pressed-selected-background: var(--seed-semantic-color-primary-low-pressed);
  --seed-capsule-toggle-button-secondary-pressed-selected-color: var(--seed-semantic-color-primary);
  --seed-capsule-toggle-button-secondary-pressed-background: var(--seed-scale-color-gray-200);
  --seed-capsule-toggle-button-secondary-pressed-color: var(--seed-scale-color-gray-900);
  --seed-capsule-toggle-button-secondary-disabled-selected-background: var(--seed-scale-color-gray-300);
  --seed-capsule-toggle-button-secondary-disabled-selected-color: var(--seed-scale-color-gray-500);
  --seed-capsule-toggle-button-secondary-disabled-background: var(--seed-scale-color-gray-300);
  --seed-capsule-toggle-button-secondary-disabled-color: var(--seed-scale-color-gray-500);
  --seed-capsule-toggle-button-small-padding-x: 14px;
  --seed-capsule-toggle-button-small-padding-y: 8px;
  --seed-capsule-toggle-button-xsmall-padding-x: 10px;
  --seed-capsule-toggle-button-xsmall-padding-y: 8px;
  --seed-capsule-toggle-button-border-radius: 9999px;
}
