.seed-text-button_pT8jKa {
  box-sizing: border-box;
  cursor: pointer;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: unset;
  border: none;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  display: inline-flex;
}

.seed-text-button_pT8jKa[data-disabled] {
  cursor: not-allowed;
}

.seed-text-button_pT8jKa[data-focus]:not([data-focus-visible]) {
  outline: none;
}

.seed-text-button_pT8jKa[data-focus][data-focus-visible] {
  outline: var(--seed-text-button-focus-border);
}

.seed-text-button_pT8jKa[data-size="large"] {
  padding: var(--seed-text-button-large-padding-y) var(--seed-text-button-large-padding-x);
}

.seed-text-button_pT8jKa[data-size="medium"] {
  padding: var(--seed-text-button-medium-padding-y) var(--seed-text-button-medium-padding-x);
}

.seed-text-button_pT8jKa[data-size="small"] {
  padding: var(--seed-text-button-small-padding-y) var(--seed-text-button-small-padding-x);
}

.seed-text-button_pT8jKa[data-size="large"][data-bold="true"] {
  font-size: var(--seed-text-button-bold-large-font-size);
  font-weight: var(--seed-text-button-bold-large-font-weight);
  line-height: var(--seed-text-button-bold-large-line-height);
  letter-spacing: var(--seed-text-button-bold-large-letter-spacing);
}

.seed-text-button_pT8jKa[data-size="medium"][data-bold="true"] {
  font-size: var(--seed-text-button-bold-medium-font-size);
  font-weight: var(--seed-text-button-bold-medium-font-weight);
  line-height: var(--seed-text-button-bold-medium-line-height);
  letter-spacing: var(--seed-text-button-bold-medium-letter-spacing);
}

.seed-text-button_pT8jKa[data-size="small"][data-bold="true"] {
  font-size: var(--seed-text-button-bold-small-font-size);
  font-weight: var(--seed-text-button-bold-small-font-weight);
  line-height: var(--seed-text-button-bold-small-line-height);
  letter-spacing: var(--seed-text-button-bold-small-letter-spacing);
}

.seed-text-button_pT8jKa[data-size="large"][data-bold="false"] {
  font-size: var(--seed-text-button-regular-large-font-size);
  font-weight: var(--seed-text-button-regular-large-font-weight);
  line-height: var(--seed-text-button-regular-large-line-height);
  letter-spacing: var(--seed-text-button-regular-large-letter-spacing);
}

.seed-text-button_pT8jKa[data-size="medium"][data-bold="false"] {
  font-size: var(--seed-text-button-regular-medium-font-size);
  font-weight: var(--seed-text-button-regular-medium-font-weight);
  line-height: var(--seed-text-button-regular-medium-line-height);
  letter-spacing: var(--seed-text-button-regular-medium-letter-spacing);
}

.seed-text-button_pT8jKa[data-size="small"][data-bold="false"] {
  font-size: var(--seed-text-button-regular-small-font-size);
  font-weight: var(--seed-text-button-regular-small-font-weight);
  line-height: var(--seed-text-button-regular-small-line-height);
  letter-spacing: var(--seed-text-button-regular-small-letter-spacing);
}

.seed-text-button_pT8jKa[data-variant="primary"] {
  color: var(--seed-text-button-primary-color);
}

.seed-text-button_pT8jKa[data-variant="primary"][data-disabled] {
  color: var(--seed-text-button-primary-disabled-color);
}

.seed-text-button_pT8jKa[data-variant="primary"][data-hover] {
  color: var(--seed-text-button-primary-hovered-color);
}

.seed-text-button_pT8jKa[data-variant="primary"][data-pressed] {
  color: var(--seed-text-button-primary-pressed-color);
}

.seed-text-button_pT8jKa[data-variant="secondary"][data-disabled] {
  color: var(--seed-text-button-secondary-disabled-color);
}

.seed-text-button_pT8jKa[data-variant="secondary"] {
  color: var(--seed-text-button-secondary-color);
}

.seed-text-button_pT8jKa[data-variant="secondary"][data-hover] {
  color: var(--seed-text-button-secondary-hovered-color);
}

.seed-text-button_pT8jKa[data-variant="secondary"][data-pressed] {
  color: var(--seed-text-button-secondary-pressed-color);
}

.seed-text-button_pT8jKa[data-variant="secondaryLow"] {
  color: var(--seed-text-button-secondary-low-color);
}

.seed-text-button_pT8jKa[data-variant="secondaryLow"][data-disabled] {
  color: var(--seed-text-button-secondary-low-disabled-color);
}

.seed-text-button_pT8jKa[data-variant="secondaryLow"][data-hover] {
  color: var(--seed-text-button-secondary-low-hovered-color);
}

.seed-text-button_pT8jKa[data-variant="secondaryLow"][data-pressed] {
  color: var(--seed-text-button-secondary-low-pressed-color);
}

.seed-text-button_pT8jKa[data-variant="accent"] {
  color: var(--seed-text-button-accent-color);
}

.seed-text-button_pT8jKa[data-variant="accent"][data-disabled] {
  color: var(--seed-text-button-accent-disabled-color);
}

.seed-text-button_pT8jKa[data-variant="accent"][data-hover] {
  color: var(--seed-text-button-accent-hovered-color);
}

.seed-text-button_pT8jKa[data-variant="accent"][data-pressed] {
  color: var(--seed-text-button-accent-pressed-color);
}

.seed-text-button_pT8jKa[data-variant="danger"][data-disabled] {
  color: var(--seed-text-button-danger-disabled-color);
}

.seed-text-button_pT8jKa[data-variant="danger"] {
  color: var(--seed-text-button-danger-color);
}

.seed-text-button_pT8jKa[data-variant="danger"][data-hover] {
  color: var(--seed-text-button-danger-hovered-color);
}

.seed-text-button_pT8jKa[data-variant="danger"][data-pressed] {
  color: var(--seed-text-button-danger-pressed-color);
}

.seed-text-button_pT8jKa[data-style="basic"] {
  text-decoration: none;
}

.seed-text-button_pT8jKa[data-style="underlined"] {
  text-underline-offset: 4px;
  text-decoration: underline;
}
